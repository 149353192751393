import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  useToast,
  VStack,
  HStack,
  Text,
  chakra,
  Box,
  Skeleton,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import { ChainID, ChainName } from "../types";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { Copy } from "lucide-react";
import { getChainIdFromName } from "src/utils/chains";
import {
  MIN_DOGE_TRANSFER_AMOUNT,
  TOAST_ERROR_DURATION,
} from "src/utils/constants";
import { useTransferContext } from "@context/TransferContext";
import DogeCustomsService from "@services/DogeCustomsService";

const CopyIcon = chakra(Copy);

export default function DepositDogeAddressModal() {
  const { sourceAddr, targetAddr, sourceChain, targetChain } =
    useTransferContext();
  const [confirming, setConfirming] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [depositAddr, setDepositAddr] = useState<string>("");
  const [txid, setTxid] = useState("");
  const [retrievingDepositAddr, setRetrievingDepositAddr] = useState(false);

  const inputTextColor = useColorModeValue("gray.800", "gray.200");
  const inputBoxWrapperBg = useColorModeValue("gray.200", "gray.800");

  const isTransfer = sourceChain === ChainName.Doge;

  const toast = useToast();

  useEffect(() => {
    if (isTransfer && targetChain && targetAddr) {
      setRetrievingDepositAddr(true);
      const chainId = getChainIdFromName(targetChain);
      if (chainId.length > 0) {
        DogeCustomsService.getDepositAddress(targetAddr ?? "", chainId[0]!)
          .then((addr) => {
            setDepositAddr(addr);
            setRetrievingDepositAddr(false);
          })
          .catch((error) => {
            toast({
              description: (error as Error).message,
              status: "error",
              duration: TOAST_ERROR_DURATION,
            });
            setRetrievingDepositAddr(false);
          });
      }
    }
  }, [targetAddr, sourceAddr, isTransfer, targetChain]);

  const onConfirm = async () => {
    setConfirming(true);
    try {
      if (!targetAddr || !depositAddr) {
        throw new Error("Receiver address is required");
      }
      // const target_chain_id = getChainIdFromName(targetChain);
      await DogeCustomsService.submitTxid({
        txid,
        receiver: targetAddr,
        target_chain_id: ChainID.Osmosis,
      });
      toast({
        description: `DOGE is on the way to ${targetChain}, stay tuned`,
        status: "success",
        duration: 5000,
      });
      setConfirming(false);
      onClose();
    } catch (error) {
      toast({
        description: (error as Error).message,
        status: "error",
        duration: TOAST_ERROR_DURATION,
      });
    }
    setConfirming(false);
  };

  const disabled = retrievingDepositAddr || !depositAddr;

  return (
    <>
      <Button
        w="100%"
        colorScheme="blue"
        size="lg"
        borderRadius={6}
        onClick={onOpen}
        disabled={disabled}
        opacity={disabled ? 0.7 : 1}
      >
        Show Deposit Address
      </Button>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isTransfer ? "Transfer" : "Redeem"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack alignItems="flex-start" pb={4}>
              {isTransfer && (
                <VStack w="100%" gap={0} mt={6}>
                  {retrievingDepositAddr || !depositAddr ? (
                    <VStack w="100%" alignItems="center">
                      <Skeleton height={168} width={168} />
                      <Skeleton height={6} width={280} />
                    </VStack>
                  ) : (
                    <VStack w="100%" alignItems="center">
                      <Box
                        bg="white"
                        p={2}
                        borderRadius={4}
                        height={168}
                        width={168}
                      >
                        {depositAddr && (
                          <QRCode
                            value={depositAddr}
                            size={152}
                            bgColor="white"
                          />
                        )}
                      </Box>
                      <HStack
                        cursor="pointer"
                        onClick={() => {
                          navigator.clipboard.writeText(depositAddr);
                          toast({
                            title: "Copied",
                            status: "success",
                            duration: 3000,
                          });
                        }}
                      >
                        <Text
                          color="blue.400"
                          maxW={window.innerWidth * 0.78}
                          textAlign="center"
                          fontFamily="monospace"
                        >
                          {depositAddr || "Missing Osmosis address"}
                        </Text>
                        <CopyIcon color="gray.300" size={16} />
                      </HStack>
                    </VStack>
                  )}

                  <Text fontSize={14} mt={2}>
                    Please deposit to this address,{" "}
                    <Text display="inline" fontWeight={600}>
                      {MIN_DOGE_TRANSFER_AMOUNT} DOGE
                    </Text>{" "}
                    at least.
                  </Text>
                </VStack>
              )}

              <Input
                color={inputTextColor}
                bg={inputBoxWrapperBg}
                py={4}
                px={2}
                lineHeight={1}
                borderWidth={0}
                fontFamily="monospace"
                height="100%"
                outline="none"
                placeholder={`Tx id`}
                value={txid}
                onChange={(e) => setTxid(e.target.value)}
                _focus={{
                  borderWidth: 0,
                  outline: "none",
                  boxShadow: "none",
                }}
              />
              <Button
                colorScheme="blue"
                w="100%"
                borderRadius={2}
                onClick={onConfirm}
                mt={4}
                disabled={!depositAddr || !txid}
                isLoading={retrievingDepositAddr || confirming}
                loadingText={
                  retrievingDepositAddr
                    ? "Generating Deposit Address"
                    : "Processing"
                }
              >
                Confirm
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
