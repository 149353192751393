import { GenerateTicketError as IcpRouteGenerateTicketError } from "../services/candids/IcpRoute.did";
import { GenerateTicketError as BitcoinCustomsGenerateTicketError } from "../services/candids/BitcoinCustoms.did";
import { SelfServiceError } from "@services/candids/OmnityHub.did";
import { GenerateTicketError as ICPCustomsGenerateTicketError } from "@services/candids/IcpCustoms.did";
import { GenerateTicketError as SolanaGenerateTicketError } from "@services/candids/eSolana.did";
import { GenerateTicketError as sSolanaGenerateTicketError } from "@services/candids/Solana.did";
import { GenerateTicketError as Brc20GenerateTicketError } from "@services/candids/BitcoinBrc20Customs.did";
import { TicketAction } from "@types";

export function formatGenerateTicketError(
  err:
    | IcpRouteGenerateTicketError
    | BitcoinCustomsGenerateTicketError
    | SelfServiceError
    | ICPCustomsGenerateTicketError
    | SolanaGenerateTicketError
    | Brc20GenerateTicketError
    | sSolanaGenerateTicketError,
) {
  const errorKey = Object.keys(err)[0];
  const errorValue = Object.values(err)[0];

  if (typeof errorValue === "string") {
    return errorKey + ":" + errorValue;
  } else if (errorValue === null) {
    return errorKey;
  } else if (typeof errorValue === "object") {
    return errorKey + ":" + JSON.stringify(errorValue);
  }
  return errorKey;
}

export function formatTicketAction(ticketType?: TicketAction) {
  if (!ticketType) {
    throw new Error("ticketType is required");
  }
  switch (ticketType) {
    case TicketAction.Mint:
      return { Mint: null };
    case TicketAction.Burn:
      return { Burn: null };
    case TicketAction.Transfer:
      return { Transfer: null };
    case TicketAction.Redeem:
      return { Redeem: null };
    default:
      throw new Error(`Invalid ticket action: ${ticketType}`);
  }
}
