import {
  Token,
  BridgeStep,
  OnBridgeParams,
  OnBurnParams,
  TicketStatusResult,
  ChainID,
  BridgeFee,
  Chain,
  Ticket,
  TxStatus,
  TicketStatus,
  TicketAction,
  GenerateTicketResult,
} from "@types";
import {
  idlFactory as SolanaRouteInterfaceFactory,
  _SERVICE,
} from "./candids/eSolana.did";
import BaseService from "./BaseService";
import { ActorSubclass } from "@dfinity/agent";
import { createActor } from "./candids";
import { PublicKey, Connection } from "@solana/web3.js";
import { SOLWalletRedeemParams } from "@wallet-kits/sol-wallet-kit/types";
import {
  formatGenerateTicketError,
  formatTicketAction,
} from "src/utils/helper";
import { getAssociatedTokenAddress, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import posthog from "posthog-js";
import { API_KEY, RPC } from "@wallet-kits/sol-wallet-kit";

export default class SolanaRouteService extends BaseService {
  actor: ActorSubclass<_SERVICE>;

  constructor(chain: Chain) {
    super(chain);
    this.actor = createActor<_SERVICE>(
      chain.canister_id,
      SolanaRouteInterfaceFactory,
    );
  }

  async getTokenList(): Promise<Token[]> {
    try {
      const tokenList = await this.actor.get_token_list();
      const tokens = await Promise.all(
        tokenList.map(async (t) => {
          try {
            const [id] = await this.actor.query_mint_address(t.token_id);
            return {
              id,
              name: t.token_id.split("-")[2],
              symbol: t.symbol,
              icon: t.icon[0],
              decimals: t.decimals,
              token_id: t.token_id,
              chain_id: this.chain.chain_id,
              balance: 0n,
              fee: 0n,
            };
          } catch (error) {
            return null;
          }
        }),
      );

      return tokens.filter((t) => t !== null) as Token[];
    } catch (error) {
      return [];
    }
  }

  async fetchTokens(token_ids?: string[], address?: string): Promise<Token[]> {
    let tokenList = this.chain.token_list || [];
    if (Array.isArray(token_ids) && token_ids.length > 0) {
      tokenList = token_ids
        .map((id) => tokenList.find((r) => r.token_id === id))
        .filter((t) => !!t) as any;
    }
    if (!address) {
      return tokenList;
    }

    const connection = new Connection(RPC, {
      httpHeaders: {
        "api-key": API_KEY,
      },
    });
    const tokens = await Promise.all(
      tokenList.map(async (t) => {
        try {
          let balance = 0n;
          if (address) {
            const ata = await getAssociatedTokenAddress(
              new PublicKey(t.id),
              new PublicKey(address),
              false,
              TOKEN_PROGRAM_ID,
            );

            const parsedAccountInfo =
              await connection.getParsedAccountInfo(ata);

            balance = BigInt(
              (parsedAccountInfo.value?.data as any).parsed.info.tokenAmount
                .amount ?? 0,
            );
          }
          return { ...t, balance, fee: 0n };
        } catch (error) {
          return t;
        }
      }),
    );
    return tokens;
  }
  getBridgeSteps(): BridgeStep[] {
    return [
      {
        title: "Bridge",
        description: "Pay Fee & Burn Token",
      },
    ];
  }
  async onBridge(params: OnBridgeParams): Promise<string> {
    const {
      targetChainId,
      token,
      sourceAddr,
      transfer,
      setStep,
      targetAddr,
      amount,
    } = params;
    if (!transfer) {
      throw new Error("transfer is required");
    }
    const feeAccount = await this.actor.get_fee_account();
    const [feeAmount] = await this.actor.get_redeem_fee(targetChainId);
    if (!feeAmount) {
      throw new Error("Solana route get_redeem_fee failed");
    }
    const ata = await getAssociatedTokenAddress(
      new PublicKey(token.id),
      new PublicKey(sourceAddr),
      false,
      TOKEN_PROGRAM_ID,
    );

    const transferParams: SOLWalletRedeemParams = {
      feeAccount,
      feeAmount,
      amount,
      token,
      tokenAccountAddress: ata.toString(),
      memo: targetAddr,
      fromAddress: sourceAddr,
    };
    const signature = await transfer(transferParams);

    setStep && setStep(1);

    return signature;
  }

  async onBurn(params: OnBurnParams): Promise<string> {
    throw new Error("Method not implemented.");
  }

  async onMint(params: OnBridgeParams): Promise<string> {
    throw new Error("Method not implemented.");
  }

  async generateTicket(ticket: Ticket): Promise<GenerateTicketResult> {
    const result = await this.actor.generate_ticket({
      signature: ticket.ticket_id!,
      action: formatTicketAction(ticket.type),
      memo: ticket.type === TicketAction.Redeem ? [ticket.receiver] : [],
      target_chain_id: ticket.dst_chain,
      token_id: ticket.token,
      amount: BigInt(ticket.amount),
      sender: ticket.sender,
      receiver: ticket.receiver,
    });

    const finializedTicket = { ticket: { ...ticket, finalized: true } };
    if ("Ok" in result) {
      posthog.capture("ticket generate ok", {
        ...ticket,
        token_id: ticket.token,
      });
      return finializedTicket;
    }
    const error = formatGenerateTicketError(result.Err);
    if (error?.includes("already exists")) {
      return finializedTicket;
    }
    posthog.capture("ticket generate error", {
      ...ticket,
      token_id: ticket.token,
      error,
    });
    return { message: error, ticket: { ...ticket, finalized: false } };
  }

  async getTicketStatus(ticket_id: string): Promise<TicketStatusResult> {
    const res = await this.actor.mint_token_status(ticket_id);
    let status = Object.keys(res)[0] as TicketStatus;
    let tx_hash;
    if (status === TicketStatus.Finalized) {
      const txHashResult = await this.actor.mint_token_tx_hash(ticket_id);
      if ("Ok" in txHashResult) {
        tx_hash = txHashResult.Ok[0];
      }
    }
    return {
      status,
      tx_hash,
    };
  }

  static async getTxStatus(ticket: Ticket): Promise<TxStatus> {
    const connection = new Connection(RPC, {
      httpHeaders: {
        "api-key": API_KEY,
      },
    });
    try {
      const result = await connection.getSignatureStatus(ticket.ticket_id!, {
        searchTransactionHistory: true,
      });
      return result.value?.confirmationStatus === "finalized"
        ? "success"
        : "pending";
    } catch (error) {
      return "reverted";
    }
  }

  async getBridgeFee(
    targetChainId: ChainID,
    token?: Token,
  ): Promise<BridgeFee> {
    const [fee] = await this.actor.get_redeem_fee(targetChainId);
    if (fee === undefined) {
      throw new Error("Failed to get redeem fee");
    }

    return {
      fee,
      symbol: "SOL",
      decimals: 9,
    };
  }

  getFeeToken() {
    return {
      symbol: "SOL",
      decimals: 9,
    };
  }

  static validateAddress(addr: string): boolean {
    try {
      const owner = new PublicKey(addr);
      return PublicKey.isOnCurve(owner);
    } catch (error) {
      return false;
    }
  }
}
