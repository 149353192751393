import { AssetType, TabAction, Token } from "../types";
import { ChevronDown, Search } from "lucide-react";
import {
  HStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  chakra,
  VStack,
  useColorModeValue,
  useToast,
  Avatar,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useTransferContext } from "@context/TransferContext";
import { formatUnits, readableNumber } from "@utils/format";
import { useHubContext } from "@context/OmnityHubContext";
import CloseButtonForModal from "./common/CloseButtonForModal";
import { TOAST_ERROR_DURATION } from "src/utils/constants";
import { useTokenList } from "@context/TokenListProvider";

const SearchIcon = chakra(Search);

interface Props {
  token?: Token;
  setToken: (t: Token) => void;
}

export default function TokenSelector({ token, setToken }: Props) {
  const { assetType } = useHubContext();
  if (assetType === AssetType.ckbtc) {
    return <TransferTokenckBTCSelector token={token} setToken={setToken} />;
  }
  return <TransferTokenSelector token={token} setToken={setToken} />;
}

function TransferTokenSelector({ token, setToken }: Props) {
  const { chains } = useHubContext();
  const { tokens, isLoadingList } = useTokenList();
  const { sourceChain, targetChain } = useTransferContext();
  const toast = useToast();

  const _tokens = useMemo(() => {
    return tokens.filter((t) => {
      if (targetChain) {
        const filteredChains = chains.filter(
          (ch) => ch.chain_name === targetChain,
        );
        return filteredChains.some((c) =>
          c?.token_list?.some((tt) => tt.token_id === t.token_id),
        );
      }
      return true;
    });
  }, [chains.length, tokens.length, targetChain, isLoadingList]);

  return (
    <BaseTokenSelector
      token={token}
      setToken={setToken}
      tokens={_tokens}
      onOpenSelector={() => {
        if (!sourceChain) {
          toast({
            title: "Please select chain first",
            status: "error",
            duration: TOAST_ERROR_DURATION,
          });
          return false;
        }
        return true;
      }}
    />
  );
}

function TransferTokenckBTCSelector({ token, setToken }: Props) {
  return (
    <BaseTokenSelector
      token={token}
      setToken={setToken}
      tokens={[]}
      selectable={false}
      onOpenSelector={() => false}
    />
  );
}

function BaseTokenSelector({
  token,
  setToken,
  tokens,
  onOpenSelector,
  selectable = true,
}: {
  token?: Token;
  setToken: (t: Token) => void;
  tokens: Token[];
  onOpenSelector: () => boolean;
  selectable?: boolean;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [keyword, setKeyword] = useState("");

  const textColor = useColorModeValue("gray.800", "gray.100");
  const boxWrapperBg = useColorModeValue("gray.200", "gray.800");
  const boxWrapperHoverBg = useColorModeValue("gray.100", "gray.800");
  const symbolColor = useColorModeValue("gray.900", "gray.200");
  const borderColor = useColorModeValue("gray.300", "gray.600");

  const _tokens = tokens.filter((t) => {
    const query = keyword.trim().toLowerCase();
    if (query) {
      if (
        t.symbol.toLowerCase().includes(query) ||
        t.name?.toLowerCase().includes(query)
      ) {
        return true;
      }
      return false;
    }
    return true;
  });

  const toast = useToast();
  const onSelectToken = async (t: Token) => {
    try {
      setToken(t);
      onClose();
    } catch (error) {
      toast({
        title: "Error",
        description: (error as Error).message,
        status: "error",
        duration: TOAST_ERROR_DURATION,
      });
    }
  };

  const { onTabActionChange } = useHubContext();

  return (
    <>
      <Button
        bg={
          !token
            ? "linear-gradient(9deg, #724AC5 -29.93%, #E43996 103.84%)"
            : boxWrapperBg
        }
        color={!token ? "white" : symbolColor}
        py={4}
        px={2}
        gap={2}
        rounded="full"
        cursor="pointer"
        _hover={{
          bg: "linear-gradient(9deg, #724AC5 -29.93%, #E43996 103.84%)",
          color: "white",
        }}
        // isLoading={isLoading}
        loadingText="Loading"
        onClick={() => {
          if (!selectable) {
            return;
          }
          if (onOpenSelector()) {
            onOpen();
          }
        }}
      >
        <Text
          fontSize={!token || !!(token.name?.length < 6) ? 20 : 12}
          fontWeight={600}
          isTruncated
          maxW={{ base: 100, md: 150 }}
        >
          {!token ? "Select token" : token.name}
        </Text>
        {!token ? (
          <ChevronDown size={24} />
        ) : (
          <Avatar name={token.symbol} src={token.icon} boxSize={8} />
        )}
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p={0}
          borderRadius={8}
          color={textColor}
          margin={{ base: 0 }}
          alignSelf={{ base: "flex-end", md: "center" }}
        >
          <ModalHeader>Select token</ModalHeader>
          <CloseButtonForModal />
          <ModalBody p={0}>
            <HStack px={6}>
              <InputGroup size="lg">
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="Search name"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </InputGroup>
            </HStack>

            <VStack
              maxH={300}
              overflowY="scroll"
              overflowX="hidden"
              overflowWrap="normal"
              willChange="transform"
              mt={2}
              gap={0}
            >
              {_tokens
                .sort((a, b) => {
                  if (a.balance > b.balance) return -1;
                  if (a.balance < b.balance) return 1;
                  return 0;
                })
                .map((t, idx) => {
                  const isLast = idx === _tokens.length - 1;
                  return (
                    <HStack
                      key={t.name}
                      w="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      py={2}
                      px={6}
                      cursor="pointer"
                      borderBottomLeftRadius={isLast ? 6 : 0}
                      borderBottomRightRadius={isLast ? 6 : 0}
                      borderBottomWidth={isLast ? 0 : 0.5}
                      borderBottomColor={borderColor}
                      _hover={{ bg: boxWrapperHoverBg }}
                      onClick={() => onSelectToken(t)}
                    >
                      <HStack>
                        <Avatar name={t.symbol} src={t.icon} boxSize={10} />
                        <VStack gap={0} alignItems="flex-start">
                          <Text
                            fontWeight={600}
                            fontSize={16}
                            lineHeight={1}
                            isTruncated
                            maxW={260}
                          >
                            {t.name}
                          </Text>
                          <Text
                            color="#999"
                            fontSize={12}
                            maxW={160}
                            isTruncated
                          >
                            {t.id}
                          </Text>
                        </VStack>
                      </HStack>
                      <Text fontWeight={600} fontSize={20}>
                        {readableNumber(formatUnits(t.balance, t.decimals), 6)}
                      </Text>
                    </HStack>
                  );
                })}
              {_tokens.length === 0 && (
                <Text mb={4} mt={2} color="gray.400">
                  No tokens, add it{" "}
                  <Text
                    display="inline"
                    cursor="pointer"
                    color="blue.400"
                    onClick={() => {
                      onTabActionChange?.(TabAction.AddRunes);
                      onClose();
                    }}
                  >
                    here
                  </Text>
                </Text>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
