import { Divider, HStack, Image, Link, Text } from "@chakra-ui/react";
import LOGO from "../assets/logo.svg";
import BitfinityLogo from "../assets/bitfinity-logo.svg";
import OMNITY from "../assets/omnity.svg";
import { ArrowUpRight } from "lucide-react";
import { useLocation } from "react-router-dom";

const LINKS = [
  {
    name: "Runes",
    url: "/runes",
  },
  {
    name: "ICP",
    url: "/icp",
  },
];

export default function Header() {
  const location = useLocation();

  return (
    <HStack
      zIndex={100}
      px={{ base: 4, md: 16 }}
      py={{ base: 2, md: 8 }}
      justifyContent="space-between"
      borderBottom="1px solid"
      borderColor="primary.alpha16"
    >
      <HStack flex={1} justifyContent="flex-start">
        <Link href="/">
          <HStack alignItems="center" justifyContent="center" gap={4}>
            <HStack alignItems="flex-start" justifyContent="center" gap={2}>
              <Image src={LOGO} alt="logo" sx={{ height: "28px" }} />
              <Image
                src={OMNITY}
                pos="relative"
                top={1}
                alt="omnity"
                display={{ base: "none", md: "inline-block" }}
              />
            </HStack>
            <Text textStyle="h6" color="primary.alpha72">
              on
            </Text>
            <HStack alignItems="center" gap={2}>
              <Image
                src={BitfinityLogo}
                alt="Bitfinity logo"
                sx={{ height: "28px" }}
              />
            </HStack>
          </HStack>
        </Link>
      </HStack>

      <HStack
        flex={1}
        justifyContent={{ base: "flex-end", md: "center" }}
        gap={{ base: 2, md: 6 }}
      >
        {LINKS.map((link) => {
          const isActive = location.pathname.startsWith(link.url);
          return (
            <Link key={link.url} href={link.url}>
              <HStack
                color={isActive ? "gray.100" : "gray.500"}
                _hover={{ color: "gray.100" }}
              >
                <Text fontSize={20} fontWeight={600}>
                  {link.name}
                </Text>
              </HStack>
            </Link>
          );
        })}
      </HStack>

      <HStack
        flex={1}
        alignItems="center"
        justifyContent="flex-end"
        display={{ base: "none", md: "flex" }}
        gap={4}
      >
        <Link
          href="https://explorer.omnity.network"
          target="_blank"
          color="text.secondary"
          sx={{
            "&:hover": {
              color: "text.secondary",
            },
          }}
        >
          <HStack>
            <Text fontSize={20} color="text.secondary">
              Explorer
            </Text>
            <ArrowUpRight size={20} color="currentColor" />
          </HStack>
        </Link>
        <Divider orientation="vertical" height="20px" bg="text.secondary" />
        <Link
          href="https://hub.omnity.network"
          target="_blank"
          color="text.secondary"
          sx={{
            "&:hover": {
              color: "text.secondary",
            },
          }}
        >
          <HStack>
            <Text fontSize={20} color="text.secondary">
              Hub
            </Text>
            <ArrowUpRight size={20} color="currentColor" />
          </HStack>
        </Link>
      </HStack>
    </HStack>
  );
}
