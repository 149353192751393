import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  Text,
  HStack,
  chakra,
  VStack,
  useColorModeValue,
  Link,
  Skeleton,
} from "@chakra-ui/react";
import { useHubContext } from "@context/OmnityHubContext";
import { ChainID, Token } from "@types";
import { ArrowUpRight, ChevronRight, PackageOpen } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { request, gql } from "graphql-request";
import { runesIndexerApi } from "@services/BitcoinCustomsService";
import { formatHash } from "@utils/format";
import dayjs from "dayjs";
import ServiceFactory from "@services/Factory";
import CloseButtonForModal from "./common/CloseButtonForModal";

const ChevronRightIcon = chakra(ChevronRight);
const ArrowUpRightIcon = chakra(ArrowUpRight);
const PackageOpenIcon = chakra(PackageOpen);

export default function BurnHistory({ token }: { token?: Token }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [txs, setTxs] = useState<BurnTxItem[]>([]);

  const boxWrapperHoverBg = useColorModeValue("gray.100", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.600");

  const { chains } = useHubContext();
  const runeId = useMemo(() => {
    const bitcoinChain = chains.find(
      (chain) => chain.chain_id === ChainID.Bitcoin,
    );
    if (bitcoinChain) {
      return bitcoinChain.token_list?.find(
        (t) => t.token_id === token?.token_id,
      )?.id;
    }
  }, [token?.token_id]);

  useEffect(() => {
    setIsLoading(true);
    if (runeId) {
      fetchBurnHistory(runeId).then((res) => {
        setTxs(res);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [runeId]);

  return (
    <>
      <HStack
        w="100%"
        mt={2}
        color="blue.400"
        justifyContent="center"
        fontSize={14}
        cursor="pointer"
        onClick={onOpen}
      >
        <Text>Burn History</Text>
        <ChevronRightIcon size={16} />
      </HStack>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          margin={{ base: 0 }}
          alignSelf={{ base: "flex-end", md: "center" }}
        >
          <ModalHeader>Burn History</ModalHeader>
          <CloseButtonForModal />
          <ModalBody px={0} pb={0} maxH={300} overflowY="scroll">
            <VStack w="100%" gap={0}>
              {txs.map((tx, idx) => {
                const isLast = idx === txs.length - 1;
                return (
                  <HStack
                    key={tx.txid}
                    w="100%"
                    px={4}
                    py={2}
                    alignItems="center"
                    justifyContent="space-between"
                    borderBottomWidth={isLast ? 0 : 0.5}
                    borderBottomColor={borderColor}
                    borderBottomLeftRadius={isLast ? 6 : 0}
                    borderBottomRightRadius={isLast ? 6 : 0}
                    _hover={{ bg: boxWrapperHoverBg }}
                  >
                    <Text fontSize={12}>
                      {dayjs(tx.timestamp).format("YYYY MMM DD, HH:mm")}
                    </Text>

                    <VStack gap={0}>
                      <Link
                        href={ServiceFactory.getTicketIdLink({
                          src_chain: ChainID.Bitcoin,
                          ticket_id: tx.txid,
                        })}
                        target="_blank"
                      >
                        <HStack gap={0}>
                          <Text color="blue.500" fontFamily="monospace">
                            {formatHash(tx.txid)}
                          </Text>

                          <ArrowUpRightIcon
                            color="blue.400"
                            strokeWidth={1}
                            size={18}
                          />
                        </HStack>
                      </Link>
                    </VStack>
                  </HStack>
                );
              })}
              {!isLoading && txs.length === 0 && (
                <VStack pb={4} color="gray.500">
                  <PackageOpenIcon size={36} strokeWidth={1} />
                  <Text>There's no burn transactions</Text>
                </VStack>
              )}
              {isLoading && txs.length === 0 && (
                <VStack w="100%" gap={2}>
                  {Array.from({ length: 5 }).map((_, idx) => (
                    <Skeleton
                      key={idx}
                      w="100%"
                      h={12}
                      isLoaded={false}
                      speed={1}
                    />
                  ))}
                </VStack>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

interface BurnTxItem {
  block: number;
  txid: string;
  timestamp: string;
}

async function fetchBurnHistory(rune_id: string) {
  try {
    const document = gql`
      {
        rune_transactions(
          where: { burn: { _eq: true }, rune_id: { _eq: "${rune_id}" } }
          limit: 10
          order_by: { timestamp: desc_nulls_first }
        ) {
          txid
          timestamp
          burn
        }
      }
    `;
    const result = await request(`${runesIndexerApi}/v1/graphql`, document);
    return (result as any).rune_transactions;
  } catch (error) {
    return [];
  }
}
