import { ActorSubclass } from "@dfinity/agent";
import { IDL } from "@dfinity/candid";
import { Chain as EvmChain } from "viem";

export enum ChainName {
  ICP = "ICP",
  Bitcoin = "Bitcoin",
  BEVM = "BEVM",
  BitLayer = "Bitlayer",
  BSquared = "B² Network",
  XLayer = "X Layer",
  Merlin = "Merlin",
  Bob = "Bob",
  Rootstock = "Rootstock",
  Bitfinity = "Bitfinity",
  AILayer = "AILayer",
  Solana = "Solana",
  Ethereum = "Ethereum",
  Core = "Core",
  Osmosis = "Osmosis",
  Ton = "Ton",
  Doge = "Dogecoin",
  Sui = "Sui",
  Base = "Base",
}

export enum ChainID {
  eICP = "eICP",
  Bitcoin = "Bitcoin",
  BEVM = "bevm",
  BitLayer = "Bitlayer",
  BSquared = "B² Network",
  XLayer = "X Layer",
  Merlin = "Merlin",
  Bob = "Bob",
  RootStock = "RootStock",
  Bitfinity = "Bitfinity",
  AILayer = "AILayer",
  sICP = "sICP",
  eSolana = "eSolana",
  Ethereum = "Ethereum",
  BitcoinBrc20 = "Bitcoinbrc20",
  Core = "Core",
  Osmosis = "osmosis-1",
  BitcoinckBTC = "BitcoinckBTC",
  Ton = "Ton",
  Doge = "Dogecoin",
  Sui = "eSui",
  Base = "Base",
  sSolana = "Solana",
}

export enum ServiceType {
  Route = "Route",
  Customs = "Customs",
}

export interface Token {
  id: string; // ICP: canisterId, BTC: rune_id, EVM: contract address
  name: string;
  symbol: string;
  decimals: number;
  icon?: string;
  balance: bigint;
  token_id: string;
  fee: bigint;
  chain_id: ChainID;
  extra?: any;
  minimum_bridge_amount?: bigint;
}

export interface TokenResp {
  decimals: number;
  token_id: string;
  icon: [] | [string];
  rune_id?: [] | [string] | string;
  symbol: string;
  metadata?: Array<[string, string]>;
}

export enum SubmitRequire {
  Select_Source_Chain = "Select source chain",
  Connect_Source_Chain_Wallet = "Connect source chain wallet",
  Select_Token = "Select token",
  Enter_Amount = "Enter amount",
  Insufficient_Balance = "Insufficient balance",
  Invalid_Amount = "Invalid amount",
  Invalid_Token = "Invalid token",
  Invalid_Chain = "Invalid chain",
  Wrong_Network = "Wrong network",
  Select_Target_Chain = "Select target chain",
  Input_Receiving_Address = "Input receiving address",
  Invalid_Address = "Invalid address",
  Confirm = "Confirm",
  Source_Chain_Not_Active = "Source chain not active",
  Target_Chain_Not_Active = "Target chain not active",
  Under_Maintenance = "Under maintenance",
  Select_Burn_Chain = "Select burn chain",
  Select_Runes = "Select Runes",
  Select_Chain = "Select chain",
  Input_Symbol = "Input symbol",
  Select_Mint_Chain = "Select mint chain",
  Connect_Mint_Wallet = "Connect mint wallet",
  Chain_Not_Active = "Chain not active",
  Invalid_Symbol = "Invalid symbol",
}

export type CustomsChainId =
  | ChainID.Bitcoin
  | ChainID.sICP
  | ChainID.BitcoinBrc20
  | ChainID.BitcoinckBTC
  | ChainID.Doge
  | ChainID.sSolana;

export type Network = "mainnet" | "testnet";

export interface OmnityWidgetProps {
  assetType: AssetType;
  customs?: CustomsChainId;
  width?: number;
  sourceChain?: ChainName;
  targetChain?: ChainName;
  reversible?: boolean;
  tokenId?: string | null;
  colorMode?: "system" | "light" | "dark";
  network?: Network;
  isPeerToPeer?: boolean;
  tab?: TabAction;
  onTabChange?: (action: TabAction) => void;
  onParamsChange?: (params: any) => void;
}

export interface Ticket {
  type?: TicketAction;
  token: string;
  dst_chain: ChainID;
  ticket_id?: string;
  memo?: string;
  ticket_time: number;
  src_chain: ChainID;
  amount: string;
  symbol?: string;
  receiver: string;
  sender: string;
  decimals?: number;
  finalized?: boolean;
  block_height?: number;
  mint_tx_hash?: string;
  new_ticket_id?: string;
}

export interface HubToken {
  decimals: number;
  icon: string[];
  issue_chain: ChainID;
  metadata: any[];
  symbol: string;
  token_id: string;
}

export enum ChainType {
  Settlement = "SettlementChain",
  ExecutionChain = "ExecutionChain",
}

export enum ChainState {
  Active = "Active",
  Deactive = "Deactive",
}

export interface Chain {
  chain_id: ChainID;
  chain_name: ChainName;
  asset_type: AssetType;
  canister_id: string;
  fee_token: string[];
  chain_type: ChainType;
  counterparties: ChainID[];
  chain_state: ChainState;
  contract_address?: string;
  evm_chain?: EvmChain;
  service_type: ServiceType;
  token_list?: Token[];
}

export interface BridgeStep {
  title: string;
  description: string;
}

export interface Addresses {
  btcAddr?: string;
  evmAddr?: string;
  icpAddr?: string;
  solAddr?: string;
  osmAddr?: string;
  tonAddr?: string;
  suiAddr?: string;
}

export type createActorFunctionType = <T>(
  canisterId: string,
  interfaceFactory: IDL.InterfaceFactory,
  sender: string,
) => Promise<ActorSubclass<T>>;

export interface OnBridgeParams {
  token: Token;
  amount: bigint;
  sourceAddr: string;
  targetAddr: string;
  targetChainId: ChainID;
  setStep?: (step: number) => void;
  feeRate?: number;
  transfer?: (params: any, other?: any) => Promise<any>;
  inscribe?: (params: any) => Promise<any>;
  createActor?: createActorFunctionType;
}

export interface OnBurnParams {
  token: Token;
  amount: bigint;
  sourceAddr: string;
  targetChainId: ChainID;
  createActor?: createActorFunctionType;
  transfer?: (params: any) => Promise<any>;
}

export enum TicketStatus {
  Unknown = "Unknown",
  Pending = "Pending",
  Confirmed = "Confirmed",
  Sending = "Sending",
  Submitted = "Submitted",
  Signing = "Signing",
  TxFailed = "TxFailed",
  Finalized = "Finalized",
}

export interface TicketStatusResult {
  status: TicketStatus;
  tx_hash?: string;
}

export type TxStatus = "success" | "reverted" | "pending" | undefined;

export enum FeeType {
  Fastest = "fastestFee",
  HalfHour = "halfHourFee",
  Hour = "hourFee",
}

export enum TabAction {
  Transfer = "Transfer",
  Burn = "Burn",
  Etch = "Etch",
  Mint = "Mint",
  AddRunes = "Add Runes",
}

export interface Rune {
  rune_id: string;
  spaced_rune: string;
  symbol: string | null;
  id: number;
  icon?: string;
  number?: number;
  divisibility: number;
}

export enum TicketAction {
  Transfer = "Transfer",
  Burn = "Burn",
  Mint = "Mint",
  Redeem = "Redeem",
}

export enum TicketInteractStep {
  START,
  SEND_TX,
  WAIT_TX,
  GEN_TICKET,
  COMPLETED,
}

export interface BridgeFee {
  fee: bigint;
  symbol: string;
  decimals: number;
}

export enum IndexerTicketStatus {
  Generating = "Generating", // local status
  Unknown = "Unknown",
  WaitingForConfirmBySrc = "WaitingForConfirmBySrc",
  WaitingForConfirmByDest = "WaitingForConfirmByDest",
  Finalized = "Finalized",
  Pending = "Pending",
}

export interface TicketItem {
  action: TicketAction;
  amount: string;
  dst_chain: ChainID;
  memo: string;
  receiver: string;
  sender: string;
  src_chain: ChainID;
  status: IndexerTicketStatus;
  ticket_id: string;
  ticket_seq: number;
  ticket_time: number;
  ticket_type: TicketAction;
  token: string;
  tx_hash?: string;
  intermediate_tx_hash?: string;
}

export interface FeeTokenPrices {
  BTC: number;
  ICP: number;
  ETH: number;
  SOL: number;
}

export enum AssetType {
  runes = "runes",
  brc20 = "brc-20",
  ckbtc = "ckbtc",
  icrc = "icrc",
  doge = "doge",
  spl = "spl",
}

export interface GenerateTicketResult {
  message?: string;
  ticket: Ticket;
}

export interface EtchParams {
  rune_name: string;
  divisibility: string;
  turbo: boolean;
  premine: string;
  symbol: string;
  logo?: {
    content_type: string;
    content_base64: string;
  };
  terms: {
    cap: string;
    height: [string, string];
    offset: [string, string];
    amount: string;
  };
}

export enum HeightType {
  Absolute = "Absolute",
  Relative = "Relative",
}

export interface Etching {
  timestamp: number;
  id: string;
  name: string;
  symbol: string;
  logo?: {
    content_type: string;
    content_base64: string;
  };
}
