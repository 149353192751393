import { AssetType, TabAction, Token } from "../types";
import { ChevronDown, Search } from "lucide-react";
import {
  HStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  Input,
  InputGroup,
  InputLeftElement,
  chakra,
  VStack,
  useColorModeValue,
  Avatar,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { formatUnits, readableNumber } from "@utils/format";
import CloseButtonForModal from "./common/CloseButtonForModal";
import { request, gql } from "graphql-request";
import { runesIndexerApi } from "@services/BitcoinCustomsService";
import { useHubContext } from "@context/OmnityHubContext";
import { useTransferContext } from "@context/TransferContext";
import { useTokenList } from "@context/TokenListProvider";

const SearchIcon = chakra(Search);

type MintToken = Token & { terms_amount?: bigint };

export default function MintTokenSelector() {
  const [keyword, setKeyword] = useState("");
  const { onTabActionChange, theme, tabAction, assetType } = useHubContext();
  const isBitfinity = theme === "bitfinity";
  const { token, onTokenChange, sourceChain, onAmountChange } =
    useTransferContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [availableTokens, setAvailableTokens] = useState<MintToken[]>([]);
  const [checkingAvaliable, setCheckingAvaliable] = useState(false);

  const textColor = useColorModeValue("gray.800", "gray.100");
  const boxWrapperBg = useColorModeValue("#eee", "gray.700");
  const boxWrapperHoverBg = useColorModeValue("gray.100", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const boxHoverWrapperBg = useColorModeValue("gray.200", "gray.600");
  const { tokens } = useTokenList();

  useEffect(() => {
    if (tabAction === TabAction.Mint && token?.id) {
      getMintableRunes([token.id]).then((result) => {
        if (result[0]) {
          onAmountChange(result[0].terms_amount?.toString() ?? "0");
        }
      });
    }
  }, [token?.token_id, tabAction]);

  useEffect(() => {
    if (sourceChain && tokens.length && assetType === AssetType.runes) {
      setCheckingAvaliable(true);

      getMintableRunes(tokens.map((t) => t.id)).then((runes) => {
        const runeIds = runes.map((r) => r.rune_id);
        const mintableTokens = tokens.filter((t) =>
          runeIds.includes(t.id),
        ) as MintToken[];
        setAvailableTokens(mintableTokens);
        setCheckingAvaliable(false);
      });
    }
  }, [sourceChain, tokens.length, assetType]);

  return (
    <>
      <HStack
        w="100%"
        bg={isBitfinity ? "bg.darkMain" : boxWrapperBg}
        _hover={{
          bg: isBitfinity ? "bg.darkHover" : boxHoverWrapperBg,
        }}
        cursor="pointer"
        py={2}
        px={2}
        borderRadius={4}
        justifyContent="space-between"
        onClick={onOpen}
      >
        <HStack>
          {token && <Avatar name={token.icon} src={token.icon} boxSize={8} />}
          <Text
            fontSize={16}
            fontWeight={token ? 600 : 400}
            lineHeight={token ? 1 : 2}
          >
            {token?.name ?? "Select Token"}
          </Text>
        </HStack>
        <ChevronDown />
      </HStack>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p={0}
          borderRadius={8}
          color={textColor}
          margin={{ base: 0 }}
          alignSelf={{ base: "flex-end", md: "center" }}
        >
          <ModalHeader>Select token</ModalHeader>
          <CloseButtonForModal />
          <ModalBody p={0}>
            <HStack px={6} pb={2} cursor="pointer">
              <Text color={textColor} fontSize={14}>
                Can't find the Runes, add it{" "}
                <Text
                  display="inline"
                  cursor="pointer"
                  color="blue.400"
                  onClick={() => {
                    onTabActionChange?.(TabAction.AddRunes);
                    onClose();
                  }}
                >
                  here
                </Text>
                .
              </Text>
            </HStack>
            <HStack px={6}>
              <InputGroup size="lg">
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="Search name"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </InputGroup>
            </HStack>

            <VStack
              maxH={300}
              overflowY="scroll"
              overflowX="hidden"
              overflowWrap="normal"
              willChange="transform"
              mt={2}
              gap={0}
            >
              {availableTokens
                .filter((t) => {
                  if (keyword === "") {
                    return true;
                  }
                  return t.name.toLowerCase().includes(keyword.toLowerCase());
                })
                .map((t, idx) => {
                  const isLast = idx === availableTokens.length - 1;
                  return (
                    <HStack
                      key={t.name}
                      w="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      py={2}
                      px={6}
                      cursor="pointer"
                      borderBottomLeftRadius={isLast ? 6 : 0}
                      borderBottomRightRadius={isLast ? 6 : 0}
                      borderBottomWidth={isLast ? 0 : 0.5}
                      borderBottomColor={borderColor}
                      _hover={{ bg: boxWrapperHoverBg }}
                      onClick={() => {
                        onTokenChange(t);
                        onAmountChange(t.terms_amount?.toString() ?? "0");
                        onClose();
                      }}
                    >
                      <HStack>
                        <Avatar name={t.symbol} src={t.icon} boxSize={10} />
                        <VStack gap={0} alignItems="flex-start">
                          <Text
                            fontWeight={600}
                            fontSize={16}
                            lineHeight={1}
                            isTruncated
                            maxW={260}
                          >
                            {t.name}
                          </Text>
                          <Text
                            color="#999"
                            fontSize={12}
                            maxW={160}
                            isTruncated
                          >
                            {t.id}
                          </Text>
                        </VStack>
                      </HStack>
                      <Text fontWeight={600} fontSize={20}>
                        {readableNumber(formatUnits(t.balance, t.decimals))}
                      </Text>
                    </HStack>
                  );
                })}
              {availableTokens.length === 0 && !checkingAvaliable && (
                <Text mb={4} mt={2} color="gray.600">
                  No mintable tokens
                </Text>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

interface RunesWithTerms {
  rune_id: string;
  block: number;
  mints: number;
  terms_cap: number;
  terms_amount: number;
  terms_height_end: number;
  terms_height_start: number;
  terms_offset_end: number;
  terms_offset_start: number;
}

async function getMintableRunes(ids: string[]) {
  try {
    const document = gql`
      {
        runes(
          where: {
          rune_id: { _in: ["${ids.join('","')}"] }
          reorg: { _eq: false }
        }) {
          block
          rune_id
          mints
          terms_cap
          terms_amount
          terms_height_end
          terms_height_start
          terms_offset_end
          terms_offset_start
          spaced_rune
        }
      }
    `;
    const res = await request(`${runesIndexerApi}/v1/graphql`, document);
    const runes: RunesWithTerms[] = (res as any).runes;
    const currentBlockHeight = await fetch(
      `https://mempool.space/api/blocks/tip/height`,
    ).then((res) => res.json());

    return runes.filter((r) => {
      if (
        !(
          r.terms_amount ||
          r.terms_cap ||
          r.terms_height_start ||
          r.terms_height_end ||
          r.terms_offset_start ||
          r.terms_offset_end
        )
      ) {
        return false;
      }
      if (r.terms_cap !== null) {
        if (r.mints >= r.terms_cap) {
          return false;
        }
      }
      if (r.terms_height_start && r.terms_height_start > currentBlockHeight) {
        return false;
      }
      if (r.terms_height_end && r.terms_height_end < currentBlockHeight) {
        return false;
      }
      if (r.terms_offset_start) {
        if (r.terms_offset_start + r.block > currentBlockHeight) {
          return false;
        }
      }
      if (r.terms_offset_end) {
        if (r.terms_offset_end + r.block < currentBlockHeight) {
          return false;
        }
      }
      return true;
    });
  } catch (error) {
    console.log("###getMintableRunes error", error);
  }

  return [];
}
