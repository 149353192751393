import {
  useAnchorWallet,
  useConnection,
  useWallet,
} from "@solana/wallet-adapter-react";
import {
  PublicKey,
  SystemProgram,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import "@solana/wallet-adapter-react-ui/styles.css";
import { createBurnInstruction, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { createMemoInstruction } from "@solana/spl-memo";
import { SOLWalletRedeemParams, SOLWalletTransportParams } from "./types";
import SolanaCustomsPortIDL from "./SolanaCustomsPortIDL.json";
import { type SolanaPortNative } from "./SolanaCustomsPort";
import { Program, AnchorProvider, BN } from "@coral-xyz/anchor";

export function useSOLWalletKit() {
  const { publicKey, signTransaction, sendTransaction, signAllTransactions } =
    useWallet();
  const { connection } = useConnection();
  const wallet = useAnchorWallet();

  const redeem = async ({
    feeAccount,
    feeAmount,
    amount,
    token,
    tokenAccountAddress,
    memo,
    fromAddress,
  }: SOLWalletRedeemParams) => {
    if (!publicKey) {
      throw new Error("Wallet not connected");
    }
    if (!signTransaction || !sendTransaction) {
      throw new Error("Wallet does not support transaction signing");
    }

    if (fromAddress !== publicKey.toString()) {
      throw new Error("Wallet address does not match");
    }

    const balance = await connection.getBalance(publicKey);
    if (BigInt(balance) <= feeAmount) {
      throw new Error("Insufficient $SOL balance");
    }

    const tx = new Transaction();

    tx.add(
      SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: new PublicKey(feeAccount),
        lamports: feeAmount,
      }),
    );

    if (tokenAccountAddress) {
      tx.add(
        createBurnInstruction(
          new PublicKey(tokenAccountAddress),
          new PublicKey(token.id),
          publicKey,
          amount,
          [],
          TOKEN_PROGRAM_ID,
        ),
      );
    }

    if (memo) {
      tx.add(createMemoInstruction(memo));
    }

    const signature = await sendTransaction(tx, connection);
    return signature;
  };

  const transport = async ({
    targetChainId,
    amount,
    receiver,
    fromAddress,
    payerAddress,
  }: SOLWalletTransportParams) => {
    if (!wallet) {
      throw new Error("Wallet not connected");
    }
    if (!publicKey) {
      throw new Error("Wallet not connected");
    }
    if (!signTransaction || !sendTransaction) {
      throw new Error("Wallet does not support transaction signing");
    }

    if (fromAddress !== publicKey.toString()) {
      throw new Error("Wallet address does not match");
    }

    const balance = await connection.getBalance(publicKey);
    if (BigInt(balance) <= amount) {
      throw new Error("Insufficient $SOL balance");
    }

    const provider = new AnchorProvider(connection, wallet, {});
    // setProvider(provider);
    const program = new Program(
      SolanaCustomsPortIDL as SolanaPortNative,
      provider,
    );

    const [port] = PublicKey.findProgramAddressSync(
      [Buffer.from("port")],
      program.programId,
    );
    const [vault] = PublicKey.findProgramAddressSync(
      [Buffer.from("vault"), port.toBuffer()],
      program.programId,
    );

    const methodIns = await program.methods
      .transport(targetChainId, receiver, new BN(amount))
      .accounts({
        // @ts-ignore
        port,
        vault,
        user: fromAddress,
        systemProgram: program.programId,
      })
      .instruction();

    const instruction = new TransactionInstruction({
      keys: [
        { pubkey: port, isSigner: false, isWritable: true },
        { pubkey: vault, isSigner: false, isWritable: true },
        {
          pubkey: new PublicKey(fromAddress),
          isSigner: true,
          isWritable: true,
        },
        { pubkey: SystemProgram.programId, isSigner: false, isWritable: false },
      ],
      programId: program.programId,
      data: methodIns.data,
    });

    const tx = new Transaction().add(instruction);
    const signature = await sendTransaction(tx, connection);
    return signature;
  };

  return {
    address: publicKey?.toString(),
    redeem,
    transport,
  };
}
